/* Scripts to enable either the HTML5 Application Cache or Web API Cache using Service Workers  */
/* globals console, window, CustomEvent */

/* An Enum of the caching capabilities of the application */
var CachingCapability = Object.freeze({ServiceWorker: 'Service Workers Supported', AppCache: 'AppCache Supported', None: 'No Cache Available'});
/* A Service worker registration object if the browser supports Service Workers. Null if not. */
var registeredServiceWorker = null;
/**
 * @description Emit Cache events using this custom event wrapper.
 * @param {String} text the text to be included in the event object.
 */
function emitCacheEvent(text) {
    "use strict";
    /* Changes here should also be made to the same emit event in dramscan.sw.js */
    var newEvent = new CustomEvent('dr-cache', {
        bubbles: true,
        detail: {
            text: text
        }
    });
    window.dispatchEvent(newEvent);
}

/**
 * @description register a service worker for caching if the browser can support it.
 */
function registerServiceWorker() {
    "use strict";
    /* Check to see if we can use service worker to cache Dramscan.
   If not we will hope the browser still supports AppCache */
    if ('serviceWorker' in navigator) {
        // todo If we need to add protocol checks first then we add them here.
        navigator.serviceWorker.register('/dramscan.sw.js')
            .then(function (registration) {
                console.log("Service worker has been registered.");

                // From the state of the registration, add an event listener to it.
                // Also set the global registeredServiceWorker to be the registration object
                if (registration.installing) {
                    registerServiceWorkerEvents(registration.installing);
                    registeredServiceWorker = registration.installing;
                } else if (registration.waiting) {
                    registerServiceWorkerEvents(registration.waiting);
                    registeredServiceWorker = registration.waiting;
                } else if (registration.active) {
                    registerServiceWorkerEvents(registration.active);
                    registeredServiceWorker = registration.active;
                }

                emitCacheEvent("App Registered. (" + registeredServiceWorker.state + ')');
            }).catch(function (error) {
                console.log('Service worker encountered an error');
                console.log(error);
        });
    }
}

/**
 * @description Checks which caching technology the browser supports if any.
 * @returns {string} A string description indicating which cache can be used for the application.
 */
function checkCacheCapability() {
    "use strict";
    if ('serviceWorker' in navigator) {
        return CachingCapability.ServiceWorker;
    } else if ('applicationCache' in window) {
        return CachingCapability.AppCache;
    } else {
        return CachingCapability.None;
    }
}

/**
 * @description Check the current cache state, checking between ServiceWorker and AppCache.
 * @returns {string} A string message to update a UI field.
 */
function getCurrentCacheState() {
    "use strict";
    console.log('Checking current cache state');
    if ('serviceWorker' in navigator) {
        console.log('Service Worker being checked...');
        // needs to be a secure origin to get this return correct.
        if (window.location.protocol === 'https:') {
            if (registeredServiceWorker) {
                console.log(registeredServiceWorker.state);
                switch (registeredServiceWorker.state) {
                    case 'activated':
                    case 'activating':
                        return 'Cached (S)';
                    default:
                        return 'Not Cached';
                }
            }
            console.log('Service worker error.');
        }
    }

    if (window.applicationCache) {
        console.log('Application Cache being checked...');
        return getAppCacheStatus();
    } else {
        return 'No Cache Available.';
    }
}

/**
 * @description Checks the status of the HTML5 application cache
 * @returns {string}
 */
function getAppCacheStatus() {
    "use strict";
    var cacheStatus = "Not supported";

    if (window.applicationCache) {
        // supported
        var appCache = window.applicationCache;
        switch (appCache.status) {
            case appCache.UNCACHED:
                cacheStatus = "Uncached";
                break;
            case appCache.IDLE:
                cacheStatus = "Cached (A)";
                break;
            case appCache.CHECKING:
                cacheStatus = "Checking";
                break;
            case appCache.DOWNLOADING:
                cacheStatus = "Downloading";
                break;
            case appCache.UPDATEREADY:
                cacheStatus = "Update ready";
                break;
            case appCache.OBSOLETE:
                cacheStatus = "Obsolete";
                break;
            default:
                cacheStatus = appCache.status.toString();
                break;
        }
    }
    return cacheStatus;
}

/**
 * @description Logs files cached by the service worker to the console.
 */
function GetCachedFilesFromServiceWorker() {
    "use strict";
    if ('serviceWorker' in navigator) {
        caches.keys().then(function(keyList) {
            keyList.map(function(key) {
                caches.open(key).then(function(cache) {
                   cache.keys().then(function(keys) {
                      keys.forEach(function(request) {
                         console.log(request.url);
                      });
                   });
                });
            });

        });
    }
}

function registerServiceWorkerEvents(worker) {
    "use strict";
    worker.addEventListener('statechange', function(event) {
        console.log('Service worker changed state');
        console.log(event.target.state);
        emitCacheEvent('App Updating ('+event.target.state+')');
    });

}

/**
 * @description Add event listening to AppCache events in the browser.
 * Will not emit anything if there is no support for AppCache.
 */
function registerAppCacheEvents() {
    "use strict";
    var appCache = window.applicationCache;
    if (appCache) {
        appCache.addEventListener('error', function () {
            // Error - probably offline
            emitCacheEvent("Stopped.");
        }, false);
        appCache.addEventListener('checking', function () {
            // checking for updates
            emitCacheEvent("Checking for updates...");
        }, false);
        appCache.addEventListener('downloading', function () {
            // downloading
            emitCacheEvent("Downloading updates...");
        }, false);
        appCache.addEventListener('progress', function (event) {
            // progress of download
            if(event.loaded) {
                emitCacheEvent(event.loaded + " of " + event.total + " downloaded.");
            }
        }, false);
        appCache.addEventListener('cached', function () {
            // application cached
            emitCacheEvent("Appcache updated.");
        }, false);
        appCache.addEventListener('idle', function () {
            // appCache functionality idle
            emitCacheEvent("Idle.");
        }, false);
        appCache.addEventListener('noupdate', function () {
            // no updates found
            emitCacheEvent("No updates found.");
        }, false);
        appCache.addEventListener('updateready', function () {
            // an update to the appCache is ready on the server
            emitCacheEvent("Application Updated.");
        }, false);
    }
}
